<template>
  <v-container>
    <div class="row">
      <v-col md="4" class="text-center">
        <v-avatar size="250px">
          <img
            v-if="user.photo"
            :src="
              user.photo !== ''
                ? user.photo
                : 'https://cdn.pixabay.com/photo/2021/07/02/04/48/user-6380868_960_720.png'
            "
          />
          <v-icon v-else color="var(--main)" large>
            mdi-account-circle-outline
          </v-icon>
        </v-avatar>
        <br />
        <v-btn
          icon
          color="white"
          class="my-1"
          style="background-color: var(--main)"
          large
        >
          <v-icon> mdi-camera-flip-outline </v-icon>
        </v-btn>
      </v-col>
      <v-col md="8">
        <v-card>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-account </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ user.firstname + " " + user.lastname }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-email </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ user.email }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-gender-male-female </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ user.gender }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon>
                  <v-icon> mdi-ticket-percent </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ totalCouponValue() }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider class="my-0"></v-divider>
          <v-card-actions>
            <v-btn
              color="var(--main)"
              class="text-light"
              @click="userEditDialog = true"
            >
              EDIT
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" ></v-col>
      <v-col cols="12" >
        <v-container></v-container>
      </v-col>
    </div>
    <v-row>
      <v-dialog v-model="userEditDialog">
        <v-card>
          <v-card-title> EDIT </v-card-title>
          <v-card-text v-model="editUser">
            <form @submit.prevent="">
              <v-text-field
                v-model="editUser.firstname"
                :counter="10"
                label="First Name"
                type="name"
                required
              ></v-text-field>
              <v-text-field
                v-model="editUser.lastname"
                :counter="10"
                label="Last Name"
                type="name"
                required
              ></v-text-field>
              <v-text-field
                v-model="editUser.email"
                label="E-mail"
                required
                type="email"
              ></v-text-field>

              <v-row>
                <v-col cols="9" >
                  <v-chip>
                    <v-icon>
                      {{ genderBit ? "mdi-gender-female" : "mdi-gender-male" }}
                    </v-icon>
                    |
                    {{ genderBit ? "Female" : "Male" }}
                  </v-chip>
                </v-col>
                <v-col cols="3" >
                  <v-switch
                    v-model="genderBit"
                    @change="toggleGender"
                    color="var(--main)"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-text-field
                v-model="editUser.description"
                label="About Me"
                required
              ></v-text-field>

              <v-btn class="mr-4"> submit </v-btn>
            </form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    editUser: {
      firstname: null,
      lastname: null,
      email: null,
      gender: null,
      description: null,
    },
    genderBit:null,
    userEditDialog: false,
    profileImgEditDialog: false,
    userPosts: [],
  }),
  methods: {
    ...mapActions(["LoadCoupons", "getUserPosts"]),
    totalCouponValue() {
      const coupons = [...this.storedCoupons];
      var totalValue = 0.0;
      if (coupons.length > 0) {
        coupons.forEach((coupon) => {
          totalValue += parseFloat(coupon.value);
        });
      }
      return totalValue;
    },
    async loadUserPosts() {
      await this.getUserPosts(this.user.uid).then( (value)=>{
        this.userPosts = value!=null?value:null
      } );
    },
    setUserDetails() {
      this.editUser.firstname = this.user.firstname;
      this.editUser.lastname = this.user.lastname;
      this.editUser.email = this.user.email;
      this.editUser.gender = this.user.gender;
      this.editUser.description = this.user.description;
    },
    toggleGender(e) {
      this.editUser.gender = e.value == true ? "Female" : "Male";
    },
  },
  created() {
    this.LoadCoupons(this.user.uid);
    this.loadUserPosts();
    this.setUserDetails();
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      storedCoupons: "getCoupons",
    }),
  },
};
</script>
